import React, { useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Modal } from 'antd';

import { USER_LOGGED_KEY_NAME } from '@/auth/constants';
import { RegistrationChoice } from '@/components/RegistrationForm/RegistrationChoice';
import { LoginAction, REDIRECT_TO_QUERY } from '@/constants/authorization';
import { FeedbackContext } from '@/context/feeadback.context';
import { UserContext } from '@/context/user.context';
import { useLoginUser } from '@/hooks/use-login-user';
import { useRedirectToAccount } from '@/hooks/use-redirect-to-account';
import { useTranslate } from '@/hooks/use-translate';
import { ymOnRegistrationClick } from '@/metrics';
import Button from '@/UI/Button';
import LabeledInput from '@/UI/LabeledInput';
import { LocalStorage } from '@/utils/local-storage';

export default function Login() {
	const [email, setEmail] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const router = useRouter();
	const redirectToAccount = useRedirectToAccount();
	const { user } = useContext(UserContext);
	const { message } = useContext(FeedbackContext);
	const t = useTranslate(en);
	const [descriptionText, setDescriptionText] = useState('');
	const { loginUser } = useLoginUser({ redirectOnSuccess: true });

	useEffect(() => {
		if (router.query.error) {
			void router.replace(router.pathname, undefined, { shallow: true });
		}
		if (router.query.action === LoginAction.Register) {
			setIsModalOpen(true);
		}
	}, [router]);

	// useEffect(() => {
	// 	if (user && LocalStorage.hasItem(USER_LOGGED_KEY_NAME)) {
	// 		if (router.query[REDIRECT_TO_QUERY]) {
	// 			const redirectUrl = router.query[REDIRECT_TO_QUERY] as string;
	// 			void router.push(redirectUrl, redirectUrl, {
	// 				locale: router.locale,
	// 			});
	// 		} else {
	// 			void redirectToAccount();
	// 		}
	// 	}
	// }, [user]);

	useEffect(() => {
		if (process.env.ENVIRONMENT === 'demo') {
			setDescriptionText(
				t(
					'Для того, чтобы попробовать платформу зарегистрируйте свою компанию или используйте одну из готовых учетных записей: fishery1@fishplace.ru или fishery2@fishplace.ru для рыбной компании, sklad@fishplace.ru для склада. Пароль для всех тестовых записей — fishplace.',
				),
			);
		} else {
			setDescriptionText(t('Введите логин и пароль'));
		}
	}, [t]);

	const handleLogin = async () => {
		try {
			setLoading(true);
			await loginUser({ email, password });
		} catch (error) {
			let errorMessage = 'Ошибка при попытке входа. Попробуйте позднее.';
			if (error.response.status === 401) {
				errorMessage = 'Неверное имя пользователя или пароль.';
			}
			message.error(errorMessage);
		} finally {
			setLoading(false);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			void handleLogin();
		}
	};

	const handleRegisterClick = () => {
		ymOnRegistrationClick();
		setIsModalOpen(!isModalOpen);
	};

	return (
		<>
			<main>
				<div className="login">
					<div className="login-inner">
						<div className="login-visual">
							<div className="login-visual-inner">
								<img alt="Fish place tiles" src="/img/login-tiles.svg" />
								<div className="login-visual-text">
									<img alt="Fish place logo" src="/img/logo.svg" />

									<span>
										{t(
											'Автоматизируйте свои бизнес-процессы и управляйте своим складом из Личного кабинета',
										)}
									</span>
								</div>
							</div>
						</div>

						<form className="login-form">
							<div className="login-form-content">
								<h2>{t('Вход')}</h2>
								<span className="login-subtitle">{descriptionText}</span>

								<div className="flex flex-col gap-[2.4rem] w-full">
									<LabeledInput
										label="Email"
										type="email"
										name="email"
										placeholder="ivan-ivanov@mail.ru"
										value={email}
										onChange={(e) => setEmail(e.currentTarget.value)}
									/>
									<LabeledInput
										inputtype="password"
										label={t('Пароль')}
										name="password"
										placeholder={t('Введите пароль')}
										value={password}
										onChange={(e) => setPassword(e.currentTarget.value)}
										onKeyDown={handleKeyDown}
									/>
								</div>

								<div className="w-full flex flex-col gap-[2rem] mt-[3rem]">
									<Button
										type="button"
										className="w-[100%] h-[5.6rem]"
										disabled={loading}
										onClick={handleLogin}
									>
										{t('Войти')}
									</Button>

									<Button
										type="button"
										className="w-[100%] h-[5.6rem]"
										onClick={handleRegisterClick}
									>
										{t('Зарегистрироваться')}
									</Button>
								</div>

								<div className="login__links_container">
									<Button variant="pale" href="/password-recovery">
										{t('Восстановить пароль')}
									</Button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</main>
			<Modal
				open={isModalOpen}
				footer={null}
				maskClosable
				keyboard
				centered
				onCancel={() => setIsModalOpen(false)}
			>
				<RegistrationChoice />
			</Modal>
		</>
	);
}

const en = {
	Вход: 'Login',
	'Введите логин и пароль': 'Enter login and password',
	Пароль: 'Password',
	'Введите пароль': 'Enter password',
	Войти: 'Sign in',
	Зарегистрироваться: 'Register',
	'Восстановить пароль': 'Restore password',
	'Автоматизируйте свои бизнес-процессы и управляйте своим складом из Личного кабинета':
		'Automate your business processes and manage your warehouse from your Personal Account',
	'Для того, чтобы попробовать платформу зарегистрируйте свою компанию или используйте одну из готовых учетных записей: fishery1@fishplace.ru или fishery2@fishplace.ru для рыбной компании, sklad@fishplace.ru для склада. Пароль для всех тестовых записей — fishplace.':
		'To try the platform, register your company or use one of the ready-made accounts: fishery1@fishplace.ru or fishery1@fishplace.ru for a fish company, sklad@fishplace.ru for a warehouse. The password for all test records - fishplace.',
};
