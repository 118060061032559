import { getYmFunction } from './getYmFunction';

const ym = getYmFunction();
const id = +process.env.YANDEX_METRIKA_ID;

export const ymOnRegistrationClick = () => {
	ym(id, 'reachGoal', 'click_register');
};

export const ymOnBuyFishRegistrationClick = () => {
	ym(id, 'reachGoal', 'click_register_fish_company_buyfish');
};

export const ymOnRegistrationFishCompanyClick = () => {
	ym(id, 'reachGoal', 'click_register_fish_company_login');
};

export const ymOnRegistrationWarehouseClick = () => {
	ym(id, 'reachGoal', 'click_register_storage_login');
};

export const ymOnFishCompanyRegistrationInput = (fieldName: string) => {
	const fieldsDictionary = {
		taxId: 'enter_tax_id_rk',
		email: 'enter_email_rk',
		phone: 'enter_phone_rk',
		password: 'enter_password_rk',
		passwordConfirmation: 'enter_repeat_password_rk',
		agreement: 'enable_user_agreement_rk',
	};

	ym(id, 'reachGoal', fieldsDictionary?.[fieldName]);
};

export const ymOnFishCompanyRegistrationSuccess = () => {
	ym(id, 'reachGoal', 'success_register_fish_company');
};

export const ymOnFishCompanyRegistrationError = () => {
	ym(id, 'reachGoal', 'failed_register_fish_company');
};

export const ymOnRegistrationWarehouseStoragePage = () => {
	ym(id, 'reachGoal', 'click_register_storage_storage');
};

export const ymOnWarehouseRegistrationStepSuccess = (step: number) => {
	ym(id, 'reachGoal', `register_storage_complete_step_${step}`);
};

export const ymOnWarehouseRegistrationSuccess = () => {
	ym(id, 'reachGoal', 'success_register_storage');
};

export const ymOnWarehouseRegistrationError = () => {
	ym(id, 'reachGoal', 'failed_register_storage');
};

export const ymOnCloseWarehouseConfirmBanner = () => {
	ym(id, 'reachGoal', 'acknowledge_storage_check');
};
