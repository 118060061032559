import { useContext } from 'react';

import { useRouter } from 'next/router';

import cookies from 'js-cookie';

import { login } from '@/api/auth/login';
import { RequestService } from '@/api/services/request.service';
import { REFRESH_TOKEN_NAME, TOKEN_COOKIE_NAME } from '@/auth/constants';
import { REDIRECT_TO_QUERY } from '@/constants/authorization';
import { FeedbackContext } from '@/context/feeadback.context';
import { UserContext } from '@/context/user.context';
import { useTranslate } from '@/hooks/use-translate';
import { Credentials } from '@/types/api/auth';
import { CompanyType } from '@/types/common-entities';

type Props = {
	redirectOnError?: boolean;
	redirectOnSuccess?: boolean;
};

export const useLoginUser = ({ redirectOnError, redirectOnSuccess }: Props = {}) => {
	const router = useRouter();
	const { message } = useContext(FeedbackContext);
	const { updateUser } = useContext(UserContext);
	const t = useTranslate(en);

	const loginUser = async ({ email, password }: Credentials): Promise<void> => {
		try {
			const { token, companyType, refresh } = await login({
				email,
				password,
			});

			cookies.set(TOKEN_COOKIE_NAME, token);
			cookies.set(REFRESH_TOKEN_NAME, refresh);
			RequestService.setAuthHeader(token);

			const user = await updateUser();

			if (!redirectOnSuccess) {
				return;
			}

			if (router.query[REDIRECT_TO_QUERY]) {
				const redirectUrl = router.query[REDIRECT_TO_QUERY] as string;
				return void router.push(redirectUrl, redirectUrl, {
					locale: router.locale,
					shallow: true,
				});
			}
			if (user?.role === 'admin') {
				return void router.push('/landing-admin', '/landing-admin', {
					locale: router.locale,
					shallow: true,
				});
			} else if (companyType === CompanyType.Warehouse) {
				return void router.push('/storage-account', '/storage-account', {
					locale: router.locale,
					shallow: true,
				});
			} else if (companyType === CompanyType.FishCompany) {
				return void router.push('/personal-account', '/personal-account', {
					locale: router.locale,
					shallow: true,
				});
			} else {
				return void router.push('/', '/', {
					locale: router.locale,
					shallow: true,
				});
			}
		} catch (e) {
			message.error(t('Произошла ошибка при попытке логина.'));
			if (redirectOnError) {
				const url = `/login?${new URLSearchParams(router.query as Record<any, any>).toString()}`;
				void router.push(url, url, { locale: router.locale });
			} else {
				throw e;
			}
		}
	};

	return {
		loginUser,
	};
};

const en = {
	'Произошла ошибка при попытке входа': 'Unexpected error during login',
};
