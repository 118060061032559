import Link from 'next/link';
import { useRouter } from 'next/router';

import { Button } from 'antd';

import { REDIRECT_TO_QUERY } from '@/constants/authorization';
import { AvoidRedirectList } from '@/constants/common';
import { useTranslate } from '@/hooks/use-translate';
import { ymOnRegistrationFishCompanyClick, ymOnRegistrationWarehouseClick } from '@/metrics';

import styles from './styles.module.scss';

export const RegistrationChoice = () => {
	const router = useRouter();
	const t = useTranslate(en);
	const redirectTo = router.query[REDIRECT_TO_QUERY] as string;
	let redirectUrl = '';
	if (redirectTo && !AvoidRedirectList.some((path) => redirectTo.includes(path))) {
		redirectUrl = `?${REDIRECT_TO_QUERY}=${redirectTo}`;
	}

	return (
		<>
			<h2 className={styles.modalTitle}>{t('Регистрация')}</h2>
			<div className={styles.modalIntro}>
				{t('Компанию какого типа вы хотите зарегистрировать?')}
			</div>
			<div className={styles.modalButtons}>
				<Link
					href={`/registration${redirectUrl}`}
					as={`/registration${redirectUrl}`}
					className={styles.modalLink}
				>
					<Button className={styles.modalButton} onClick={ymOnRegistrationFishCompanyClick}>
						{t('Рыбная компания')}
					</Button>
				</Link>
				<Link
					href={`/register-storage${redirectUrl}`}
					as={`/register-storage${redirectUrl}`}
					className={styles.modalLink}
				>
					<Button className={styles.modalButton} onClick={ymOnRegistrationWarehouseClick}>
						{t('Холодильный склад')}
					</Button>
				</Link>
			</div>
		</>
	);
};

const en = {
	Регистрация: 'Registration',
	'Компанию какого типа вы хотите зарегистрировать?':
		'What type of company do you want to register?',
	'Холодильный склад': 'Warehouse',
	'Рыбная компания': 'Fish company',
};
