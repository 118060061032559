import { useCallback, useContext } from 'react';

import { useRouter } from 'next/router';

import { REDIRECT_TO_QUERY } from '@/constants/authorization';
import { UserContext } from '@/context/user.context';
import { CompanyType } from '@/types/common-entities';

export const useRedirectToAccount = () => {
	const router = useRouter();
	const { user } = useContext(UserContext);

	return useCallback(() => {
		const host = router.locale === 'en' ? `/${router.locale}` : '';
		let redirectPageUrl = `${host}${router.query[REDIRECT_TO_QUERY]}`;
		if (router.query[REDIRECT_TO_QUERY]) {
			return void router.push(redirectPageUrl, redirectPageUrl, { shallow: true });
		} else {
			redirectPageUrl = `${host}/personal-account`;
			if (user.company_type === CompanyType.Warehouse) {
				redirectPageUrl = `${host}/storage-account`;
			}
		}
		void router.push(redirectPageUrl, redirectPageUrl, { shallow: true });
	}, [user]);
};
